<template>
  <div style="margin-top:20px;">
    <el-table :data="tableData" border style="width: 100%">
      <el-table-column :prop="item.prop" :label="item.label" v-for="(item,index) in props.cloData" :key="index">
      </el-table-column>
      <el-table-column prop="status" label="审核状态">
        <template #default="{row}">
          <span>{{ fn[row.status] }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="client" label="用户ID">
        <template #default="{row}">
          <span>{{ row.id }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="client" label="用户昵称">
        <template #default="{row}">
          <span>{{ row.nick }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="address" label="操作">
        <template #default="{row}">
          <el-button type="primary" @click="EdithData(row)">修改</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script setup>
import { ref } from "vue"
const fn = ref({
  0: "资料填写",
  1: "人脸识别",
  2: "等待审核",
  3: "审核失败",
  4: "审核成功",
})
const props = defineProps({
  cloData: {
    type: Array,
    default: []
  },
  tableData: {
    type: Array,
    default: []
  }
})
const emit = defineEmits(['EdithData'])
const EdithData = (e) => {
  emit('EdithData', e)
}
</script>
<style lang="scss" scoped>
</style>