<template>
  <div>
    <div class="searchValue">
      <search @seaCher="seaCher" @rest="rest" v-model:value="searchValue"></search>
    </div>
    <div>
      <dataTable :tableData="tableData" :cloData="cloData" @operate="operate"></dataTable>
    </div>
    <div>
      <pagination :painData="painData" @handleSizeChange="handleSizeChange" @handleCurrentChange="handleCurrentChange"></pagination>
    </div>
    <div>
      <diaLog v-model:DialogVisible="DialogVisible" @close="close"></diaLog>
    </div>
  </div>
</template>
<script setup>
import { ref, onMounted, reactive } from "vue"
import { getAuthorList, upAuthorList } from "@/config/http.js"
import search from "@/components/search.vue"
import pagination from "@/components/pagination.vue"
import dataTable from "./authorComponents/dataTable.vue"
import diaLog from "./authorComponents/diaLog.vue"
const tableData = ref()
const searchValue = ref()
const painData = reactive({
  total: 0,//一共多少条
  pageSize: 10, //一页多少条
  currentPage: 1, //第几页
})
const DialogVisible = ref(false)
const cloData = reactive([
  {
    prop: "id",
    label: "ID"
  },
  {
    prop: "name",
    label: "昵称"
  },
  {
    prop: "id_card",
    label: "身份证号码"
  },
  {
    prop: "wx_account",
    label: "微信号"
  },
  {
    prop: "shop_account",
    label: "商户号"
  },
  {
    prop: "advice",
    label: "备注"
  },
])
const getList = () => {
  const params = ({
    'page[number]': painData.currentPage,
    'page[size]': painData.pageSize
  })
  getAuthorList(params).then((res) => {
    tableData.value = res.data
    painData.total = res.total
  })
}
const handleSizeChange = (val) => { //上一页
  painData.currentPage = val
  getList()
}
const handleCurrentChange = (val) => { //下一页
  painData.currentPage = val
  getList()
}
const operate = (e) => {
  // console.log(e, '11111')
  DialogVisible.value = true
}
const close = () => {
  DialogVisible.value = false
}
onMounted(() => {
  getList()
})
const seaCher = (e) => { //查询
}
const rest = () => { //重置
  getList()
}
</script>
<style lang="scss">
.searchValue {
  display: flex;
}
</style>