<!--
 * @Author: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @Date: 2024-04-15 10:57:12
 * @LastEditors: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @LastEditTime: 2024-05-17 15:20:45
 * @FilePath: \demo_ele\src\views\labelPage\components\detailLog.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div v-if="showStatus == 1">
    <el-dialog v-model="dialogVisible" :title="props.title" width="40%" draggable @close="close(dialogVisible)">
      <el-table :data="props.detailTable" stripe style="width: 100%">
        <el-table-column :prop="item.prop" :label="item.label" v-for="(item,index) in detailCloum" :key="index" />
        <el-table-column prop="detailTable" label="状态">
          <template #default="{row}">
            <el-switch v-model="row.status" :active-value="1" :inactive-value="0" disabled></el-switch>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
  <div v-if="showStatus == 2">
    <el-dialog v-model="dialogVisible" :title="props.title" width="30%" draggable @close="close(dialogVisible)">
      <el-form :model="props.form">
        <el-form-item :label-width="formLabelWidth" v-for="(item,index) in createData" :key="index" :label="item.label">
          <formList v-model:value="props.form[item.value]" :itemList="item" />
        </el-form-item>
      </el-form>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="close(dialogVisible)">取消</el-button>
          <el-button type="primary" @click="submit(form)">
            确定
          </el-button>
        </div>
      </template>
    </el-dialog>
  </div>
  <div v-if="showStatus == 3">
    <el-dialog v-model="dialogVisible" :title="props.title" width="40%" draggable @close="close(dialogVisible)">
      <el-form :model="upFromData">
        <el-form-item :label-width="formLabelWidth" v-for="(item,index) in createData" :key="index" :label="item.label">
          <formList v-model:value="upFromData[item.value]" :itemList="item" />
        </el-form-item>
      </el-form>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="close(dialogVisible)">取消</el-button>
          <el-button type="primary" @click="upSubmit(upFromData)">
            确定
          </el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>
<script setup>
import { ref, watch, reactive } from "vue"
import formList from "@/components/formList.vue"
const props = defineProps({
  dialogVisible: {
    type: Boolean,
    default: null
  },
  detailId: {
    type: String,
    default: ""
  },
  title: {
    type: String,
    default: ""
  },
  detailTable: {
    type: Array,
    default: ""
  },
  showStatus: {
    type: Number,
    default: ""
  },
  form: {
    type: Object,
    default: () => { },
  },
  upFromData: {
    type: Array,
    default: []
  }
})
const dialogVisible = defineModel('dialogVisible')
const formLabelWidth = ref(120)
const detailCloum = ref([
  { prop: "sort", label: "权重" },
  { prop: "id", label: "ID" },
  { prop: "name", label: "昵称" },
  { prop: "updated_at", label: "修改时间" },
])
const emit = defineEmits(['getStatus', 'submit', 'upSubmit'])
const createData = reactive([
  {
    label: "昵称",
    value: "name",
    type: "input"
  },
  {
    label: "权重",
    value: "sort",
    type: "input"
  },
  {
    label: "状态",
    value: "status",
    type: "switch"
  }
])
const close = (e) => {
  emit('getStatus', e)
}
const submit = (e) => {
  emit('submit', e)
}
const upSubmit = (e) => {
  emit('upSubmit', e)
}
</script>
<style lang="scss" scoped>
</style>