<template>
  <div id="wrapper">
    <div class="searchValue">
      <search @seaCher="seaCher" @rest="rest"></search>
    </div>
    <div>
      <tableDataDetails :tableData="tableData" :cloData="cloData" @EdithData="EdithData"></tableDataDetails>
    </div>
  </div>
  <div>
    <pagination :painData="painData" @handleSizeChange="handleSizeChange" @handleCurrentChange="handleCurrentChange"></pagination>
  </div>
  <div>
    <upLog :dialogVisible="dialogVisible" :upData="upData" @getStatus="getStatus" @submit="submit" :showStatus="showStatus"></upLog>
  </div>
</template>
<script setup>
import { ref, onMounted, reactive } from "vue"
import { getAuthorReviewList, AuthorReview } from "@/config/http"
import { ElMessage, ElMessageBox } from 'element-plus'
import upLog from "./audiComponents/upLog.vue"
import search from "@/components/search.vue"
import pagination from "@/components/pagination.vue"
import tableDataDetails from "./audiComponents/tableData.vue"
const tableData = ref()
const upData = ref()
const dialogVisible = ref(false)
const showStatus = ref(1)
const cloData = ref([
  { prop: "name", label: "昵称" },
  { prop: "id_card", label: "身份证号" },
  { prop: "wx_account", label: "微信号" },
  { prop: "shop_account", label: "商户号" },
  { prop: "advice", label: "审核意见" },
  { prop: "audit_at", label: "审核时间" },
  { prop: "created_at", label: "申请时间" },
])

const painData = reactive({
  total: 0,//一共多少条
  pageSize: 10, //一页多少条
  currentPage: 1, //第几页
})
onMounted(() => {
  getList()
})
const getList = () => {
  const params = ({
    'page[number]': painData.currentPage,
    'page[size]': painData.pageSize
  })
  getAuthorReviewList(params).then((res) => {
    tableData.value = res.data
    painData.total = res.total
  })
}
const handleSizeChange = (val) => { //上一页
  painData.currentPage = val
  getList()
}
const handleCurrentChange = (val) => { //下一页
  painData.currentPage = val
  getList()
}
const getStatus = (e) => { //子组件的关闭父组件的弹框要设置为false否则第二次点击状态还是为true组建的为false
  if (e == true) {
    dialogVisible.value = false
    getList()
  }
}
const EdithData = (e) => {
  upData.value = e
  dialogVisible.value = true
  showStatus.value == 1
}
const submit = (e, status) => { //修改操作
  console.log(e, status)
  ElMessageBox.confirm(
    '此操作将修改该数据, 是否继续?', '提示',
    {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning',
    }
  ).then(() => {
    const id = e.id
    const params = {
      status: status.value,
      advice: e.advice
    }
    AuthorReview(id, params).then((res) => {
      ElMessage({
        type: 'success',
        message: "修改成功",
      })
      getList()
      dialogVisible.value = false
    }).catch(() => {
      ElMessage({
        type: 'info',
        message: "修改失败",
      })
      dialogVisible.value = false
      getList()
    })
  }).catch(() => { })

}
</script>
<style lang="scss" scoped>
.searchValue {
  display: flex;
}
</style>