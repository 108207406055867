<template>
  <div id="wrapper">
    <div class="searchValue">
      <search @seaCher="seaCher" @rest="rest"></search>
    </div>
    <div style="margin-top:20px;">
      <el-table :data="tableData" border style="width: 100%">
        <el-table-column prop="title" label="标题" />
        <el-table-column prop="link" label="链接" />
        <el-table-column prop="tableData" label="图片">
          <template #default="{row}">
            <div alt="" v-for="(item,index) in JSON.parse(row.images)" :key="index">
              <img :src="item" alt="" style="width:50px;height:50px;">
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="status" label="审核状态">
          <template #default="{row}">
            <span>{{ fn[row.status] }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="created_at" label="创建时间" />
        <el-table-column prop="address" label="操作">
          <template #default="{row}">
            <el-button type="primary" @click="EdithData(row)">修改</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div>
      <pagination :painData="painData" @handleSizeChange="handleSizeChange" @handleCurrentChange="handleCurrentChange"></pagination>
    </div>
    <div>
      <upLog :dialogVisible="dialogVisible" :upData="upData" @getStatus="getStatus" @submit="submit" :showStatus="showStatus"></upLog>
    </div>
  </div>
</template>
<script setup>
import { ref, onMounted, reactive } from "vue"
import { getDiaryList, DiaryReview } from "@/config/http.js"
import upLog from "./audiComponents/upLog.vue"
import { ElMessage, ElMessageBox } from 'element-plus'
import search from "@/components/search.vue"
import pagination from "@/components/pagination.vue"
const tableData = ref()
const upData = ref()
const showStatus = ref(2)
const dialogVisible = ref(false)
const fn = ref({
  0: "资料填写",
  1: "人脸识别",
  2: "等待审核",
  3: "审核失败",
  4: "审核成功",
})
const painData = reactive({
  total: 0,//一共多少条
  pageSize: 10, //一页多少条
  currentPage: 1, //第几页
})
const getList = () => {
  const params = ({
    'page[number]': painData.currentPage,
    'page[size]': painData.pageSize
  })
  getDiaryList(params).then((res) => {
    tableData.value = res.data
    painData.total = res.total
  })
}
const handleSizeChange = (val) => { //上一页
  painData.currentPage = val
  getList()
}
const handleCurrentChange = (val) => { //下一页
  painData.currentPage = val
  getList()
}
onMounted(() => {
  getList()
})
const EdithData = (e) => {
  upData.value = e
  // console.log(upData.value)
  dialogVisible.value = true
}
const submit = (e, status) => {
  const id = e.id
  const data = {
    status: status.value,
    advice: e.audit_refuse_reason
  }
  DiaryReview(id, data).then((res) => {
    ElMessage({
      type: 'success',
      message: "修改成功",
    })
    getList()
    dialogVisible.value = false
  }).catch(() => {
    ElMessage({
      type: 'info',
      message: "修改失败",
    })
    dialogVisible.value = false
    getList()
  })
}
const getStatus = (e) => { //子组件的关闭父组件的弹框要设置为false否则第二次点击状态还是为true组建的为false
  if (e == true) {
    dialogVisible.value = false
    getList()
  }
}
const seaCher = () => { }
const rest = () => {
  getList()
}
</script>
<style lang="scss" scoped>
.searchValue {
  display: flex;
}
</style>