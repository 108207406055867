<!--
 * @Author: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @Date: 2024-04-19 15:16:27
 * @LastEditors: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @LastEditTime: 2024-04-23 15:42:00
 * @FilePath: \demo_ele\src\views\authorPage\authorComponents\dataTable.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div style="margin-top:20px;">
    <el-table :data="props.tableData" border style="width: 100%">
      <el-table-column :prop="item.prop" :label="item.label" v-for="(item,index) in props.cloData" :key="index">
      </el-table-column>
      <el-table-column prop="props.tableData" label="设备类型">
        <template #default="{row}">
          <span>{{ fn[row.device_type] }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="tableData" label="操作">
        <template #default="{row}">
          <el-button type="primary" @click="upData(row)">修改</el-button>
          <el-button type="primary" @click="detailData(row)">详情</el-button>
          <el-button type="danger" @click="deleteData(row.id)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script setup>
import { ref } from "vue"
const fn = ref({
  1: "IOS",
  2: "安卓"
})
const props = defineProps({
  tableData: {
    type: Object,
    default: []
  },
  cloData: {
    type: Array,
    default: []
  },
  showStatus: {
    type: String,
    default: ""
  },
})
const emit = defineEmits(['upData', 'detailData', 'deleteData'])
const upData = (e) => {
  emit('upData', e)
}
const detailData = (e) => {
  emit('detailData', e)
}
const deleteData = (e) => {
  emit('deleteData', e)
}
</script>
<style lang="scss">
</style>