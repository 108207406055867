import chitchatUser from "@/views/chitchatPage/chitchatUser.vue"
const chatRouts = [
  {
    path: '/chitchatUser',
    name: '聊天管理',
    meta: { title: "聊天信息" },
    children: [
      {
        path: '/chitchatUser',
        name: '聊天记录',
        component: chitchatUser,
      }
    ]
  },
]
export default chatRouts

