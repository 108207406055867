<template>
  <div v-if="showStatus == 1">
    <el-dialog v-model="dialogVisible" title="用户信息" width="60%" draggable>
      <el-tabs v-model="activeName" class="demo-tabs">
        <el-tab-pane label="用户详情" name="first">
          <userDetail :detailId="props.detailId" :key="jgFileTimer"></userDetail>
        </el-tab-pane>
        <el-tab-pane label="用户关注列表" name="second">
          <attentionDetail :detailId="props.detailId" :key="jgFileTimer"></attentionDetail>
        </el-tab-pane>
        <el-tab-pane label="用户粉丝列表" name="third">
          <userFsDetail :detailId="props.detailId" :key="jgFileTimer"></userFsDetail>
        </el-tab-pane>
        <el-tab-pane label="用户日记" name="fourth">
          <userDiaryDetail :detailId="props.detailId" :key="jgFileTimer"></userDiaryDetail>
        </el-tab-pane>
        <el-tab-pane label="用户收藏" name="five">
          <userCollectDetail :detailId="props.detailId" :key="jgFileTimer"></userCollectDetail>
        </el-tab-pane>
        <el-tab-pane label="用户日记点赞列表" name="six">
          <userDiaryikesDetails :detailId="props.detailId" :key="jgFileTimer"></userDiaryikesDetails>
        </el-tab-pane>
        <el-tab-pane label="用户形象照" name="seven">
          <imagePhotoDetail :detailId="props.detailId" :key="jgFileTimer"></imagePhotoDetail>
        </el-tab-pane>
        <el-tab-pane label="客户黑名单" name="eight">
          <userBlackDetail :detailId="props.detailId" :key="jgFileTimer"></userBlackDetail>
        </el-tab-pane>
      </el-tabs>
    </el-dialog>
  </div>
  <div>
    <el-dialog v-model="uplogVisible" title="修改" width="40%" draggable>
      <el-form :model="upData">
        <el-form-item label="请选择是否精选作者" label-width="150">
          <el-switch v-model="upData.is_featured" :active-value="1" :inactive-value="0"></el-switch>
        </el-form-item>
        <el-form-item label="请输入基数" label-width="150">
          <el-input v-model="upData.popularity" autocomplete="off" />
        </el-form-item>
      </el-form>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="uplogVisible = false">取消</el-button>
          <el-button type="primary" @click="submit(upData)">确定</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
  <div v-if="showStatus == 2">
    <el-dialog v-model="dialogVisible" title="审核管理" width="50%" draggable>
      <div>
        <el-form :inline="true" :model="formInline" class="demo-form-inline">
          <el-form-item label="当前审核状态 : ">
            <el-input v-model="all" disabled />
          </el-form-item>
          <el-form-item label="可修改为 : ">
            <el-select v-model="formInline.region" placeholder="请选择要修改的状态" @change="handleSelect" style="width: 200px">
              <el-option label="审核成功" value="4" />
              <el-option label="审核失败" value="3" />
            </el-select>
          </el-form-item>
          <el-form-item label="审核意见 : ">
            <el-input v-model="formInline.advice" />
          </el-form-item>
        </el-form>
      </div>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="dialogVisible = false">取消</el-button>
          <el-button type="primary" @click="submit">确定</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>
<script setup>
const props = defineProps({
  detailId: {
    type: String,
    default: ""
  },
  upData: {
    type: Object,
    default: ""
  },
  showStatus: {
    type: Number,
    default: ""
  }
})
import { ref } from "vue"
import userDetail from "@/views/userComponent/components/userDetail.vue"
import attentionDetail from "@/views/userComponent/components/attentionDetail.vue"
import userFsDetail from "@/views/userComponent/components/userFsDetail.vue"
import userDiaryDetail from "@/views/userComponent/components/userDiaryDetail.vue"
import userCollectDetail from "@/views/userComponent/components/userCollectDetail.vue"
import userDiaryikesDetails from "@/views/userComponent/components/userDiaryikesDetails.vue"
import imagePhotoDetail from "@/views/userComponent/components/imagePhotoDetail.vue"
import userBlackDetail from "@/views/userComponent/components/userBlackDetail.vue"
const dialogVisible = defineModel('dialogVisible')
const uplogVisible = defineModel('uplogVisible')
const activeName = ref('first')
const emit = defineEmits(['submit'])
const submit = (e) => {
  emit('submit', e)
}
</script>
<style lang="scss">
</style>