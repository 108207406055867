<template>
  <div id="wrapper">
    <div class="searchValue">
      <search @seaCher="seaCher" @rest="rest"></search>
    </div>
    <div>
      <el-button type="primary" @click="setData" style="float:right">
        <el-icon>
          <Plus />
        </el-icon>
        <span>
          创建标签
        </span>
      </el-button>
    </div>
    <div style="margin-top:20px;">
      <tableDataDetails :tableData="tableData" @upData="upData" @detailData="detailData" @deleteData="deleteData"></tableDataDetails>
    </div>
  </div>
  <div>
    <!-- 分页 -->
    <pagination :painData="painData" @handleSizeChange="handleSizeChange" @handleCurrentChange="handleCurrentChange"></pagination>
  </div>
  <div>
    <!-- 弹框 -->
    <detailLog v-model:dialogVisible="dialogVisible" :detailId="detailId" :title="title" :detailTable="detailTable" @getStatus="getStatus" :showStatus="showStatus" :form="form" @submit="submit" :upFromData="upFromData" @upSubmit="upSubmit"></detailLog>
  </div>
</template>
<script setup>
import { ref, onMounted, reactive } from "vue"
import { TagList, TagDetails, establishTag, upBliShTag, deleteBliShTag } from "@/config/http.js"
import { ElMessage, ElMessageBox } from 'element-plus'
import pagination from "@/components/pagination.vue"
import search from "@/components/search.vue"
import detailLog from "./components/detailLog.vue"
import tableDataDetails from "./components/tableData.vue"
const dialogVisible = ref(false)
const detailId = ref()
const title = ref()
const detailTable = ref()
const upFromData = ref()
const upId = ref()
const showStatus = ref(0)
const painData = reactive({
  total: 0,//一共多少条
  pageSize: 10, //一页多少条
  currentPage: 1, //第几页
})
const form = reactive({
  name: "",
  status: false,
  sort: "",
})
const tableData = ref()
const getList = () => {
  const params = ({
    'page[number]': painData.currentPage,
    'page[size]': painData.pageSize
  })
  TagList(params).then((res) => { //获取数据
    tableData.value = res.data
    painData.total = res.total
  })
}
const handleSizeChange = (val) => { //上一页
  painData.currentPage = val
  getList()
}
const handleCurrentChange = (val) => { //下一页
  painData.currentPage = val
  getList()
}
onMounted(() => {
  getList()
})
const setData = () => { //创建
  dialogVisible.value = true
  showStatus.value = 2
  title.value = "创建标签"
}
const detailData = (e) => { //详情
  detailId.value = e
  showStatus.value = 1
  dialogVisible.value = true
  title.value = "标签详情"
  TagDetails(e).then((res) => {
    detailTable.value = [res.data]
  })
}
const getStatus = (e) => { //子组件的关闭父组件的弹框要设置为false否则第二次点击状态还是为true组建的为false情况文本框
  if (e == true) {
    form.name = ""
    form.status = false
    form.sort = ""
    dialogVisible.value = false

  }
}
const submit = (e) => { //创建
  ElMessageBox.confirm(
    '确认创建该数据吗', '提示',
    {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning',
    }
  ).then(() => {
    const params = {
      name: e.name,
      status: e.status,
      sort: e.sort,
    }
    establishTag(params).then((res) => {
      ElMessage({
        type: 'success',
        message: "创建成功",
      })
      dialogVisible.value = false
      getList()
      e.name = ""
      e.status = false
      e.sort = ""
    }).catch(() => {
      ElMessage({
        type: 'info',
        message: "创建失败",
      })
      dialogVisible.value = false
      e.name = ""
      e.status = false
      e.sort = ""
    })
  }).catch(() => { })

}
const upData = (e) => { //修改
  upFromData.value = e
  title.value = "修改标签"
  dialogVisible.value = true
  upId.value = e.id
  showStatus.value = 3
}
const upSubmit = (e) => {
  ElMessageBox.confirm(
    '此操作将修改该数据, 是否继续?', '提示',
    {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning',
    }
  ).then(() => {
    const id = upId.value
    const params = {
      name: e.name,
      sort: e.sort,
      status: e.status
    }
    console.log(id, params, '======')
    upBliShTag(id, params).then((res) => {
      ElMessage({
        type: 'success',
        message: "修改成功",
      })
      dialogVisible.value = false
      getList()
    }).catch(() => {
      ElMessage({
        type: 'info',
        message: "修改失败",
      })
      dialogVisible.value = false
      getList()
    })
  }).catch(() => { })
}
const deleteData = (e) => { //删除
  ElMessageBox.confirm(
    '此操作将删除该数据, 是否继续?', '提示',
    {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning',
    }
  ).then(() => {
    deleteBliShTag(e).then((res) => {
      ElMessage({
        type: 'success',
        message: "删除成功",
      })
      getList()
    }).catch(() => {
      ElMessage({
        type: 'error',
        message: "删除失败",
      })
    })
  }).catch(() => { })
}
const seaCher = () => { //搜索

}
const rest = () => { //重置
  getList()
}
</script>
<style lang="scss" scoped>
.searchValue {
  display: flex;
}
</style>