<!--
 * @Author: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @Date: 2024-04-18 16:08:42
 * @LastEditors: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @LastEditTime: 2024-04-23 15:13:04
 * @FilePath: \demo_ele\src\views\auditPage\problemFeedback.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div id="wrapper">
    <div class="searchValue">
      <search @seaCher="seaCher" @rest="rest"></search>
    </div>
    <div style="margin-top:20px;">
      <el-table :data="tableData" border style="width: 100%">
        <el-table-column prop="id" label="ID" />
        <el-table-column prop="type" label="类型" />
        <el-table-column prop="phone" label="手机号" />
        <el-table-column prop="img" label="图片">
          <!-- <template #default="{row}">
            <div alt="" v-for="(item,index) in row.img" :key="index">
              <img :src="item" alt="" style="width:50px;height:50px;">
            </div>
          </template> -->
        </el-table-column>
        <el-table-column prop="created_at" label="创建时间" />
        <el-table-column prop="updated_at" label="修改时间" />
      </el-table>
    </div>
    <div>
      <pagination :painData="painData" @handleSizeChange="handleSizeChange" @handleCurrentChange="handleCurrentChange"></pagination>
    </div>
  </div>
</template>
<script setup>
import { ref, onMounted, reactive } from "vue"
import { getRobFeedback } from "@/config/http.js"
import search from "@/components/search.vue"
import pagination from "@/components/pagination.vue"
const tableData = ref()
const painData = reactive({
  total: 0,//一共多少条
  pageSize: 10, //一页多少条
  currentPage: 1, //第几页
})
onMounted(() => {
  getList()
})
const getList = () => {
  const params = ({
    'page[number]': painData.currentPage,
    'page[size]': painData.pageSize
  })
  getRobFeedback(params).then((res) => { //获取列表
    tableData.value = res.data
    painData.total = res.total
  })
}
const rest = () => { //重置
  // getList()
}
const seaCher = (val) => { //搜索
  // console.log(val, '12')
}
</script>
<style lang="scss">
.searchValue {
  display: flex;
}
</style>