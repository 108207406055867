import userInfo from "@/views/homePage/userMange/userInfo.vue"
import labelUser from "@/views/labelPage/labelUser.vue"
const pageRouts = [
  {
    path: '/userInfo',
    name: '用户管理',
    meta: { title: "用户列表" },
    children: [
      {
        path: '/userInfo',
        name: '用户列表',
        component: userInfo,
      },
      {
        path: '/labelUser',
        name: '标签列表',
        component: labelUser,
      }
    ]
  }

]
export default pageRouts
