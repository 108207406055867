<!--
 * @Author: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @Date: 2024-04-18 09:23:39
 * @LastEditors: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @LastEditTime: 2024-04-27 10:07:48
 * @FilePath: \demo_ele\src\views\auditPage\reportUser.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div id="wrapper">
    <div class="searchValue">
      <search @seaCher="seaCher" @rest="rest"></search>
    </div>
    <div style="margin-top:20px;">
      <el-table :data="tableData" border style="width: 100%">
        <el-table-column prop="created_at" label="拉黑时间" />
        <el-table-column prop="images" label="图片" />
        <el-table-column prop="tableData" label="被拉黑ID">
          <template #default="{row}">
            <span>{{ row.creator.id }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="tableData" label="被拉黑人昵称">
          <template #default="{row}">
            <span>{{ row.creator.nick }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="tableData" label="拉黑人ID">
          <template #default="{row}">
            <span>{{ row.client.id }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="tableData" label="拉黑人昵称">
          <template #default="{row}">
            <span>{{ row.client.nick }}</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div>
      <pagination :painData="painData" @handleSizeChange="handleSizeChange" @handleCurrentChange="handleCurrentChange"></pagination>
    </div>
  </div>
</template>
<script setup>
import { ref, onMounted, reactive } from "vue"
import { getUserBlockList } from "@/config/http.js"
import search from "@/components/search.vue"
import pagination from "@/components/pagination.vue"
const tableData = ref()
const painData = reactive({
  total: 0,//一共多少条
  pageSize: 10, //一页多少条
  currentPage: 1, //第几页
})
const getList = () => {
  const params = ({
    'page[number]': painData.currentPage,
    'page[size]': painData.pageSize
  })
  getUserBlockList(params).then((res) => {
    tableData.value = res.data
    painData.total = res.total
  })
}
const handleSizeChange = (val) => { //上一页
  painData.currentPage = val
  getList()
}
const handleCurrentChange = (val) => { //下一页
  painData.currentPage = val
  getList()
}
onMounted(() => {
  getList()
})
const rest = () => { //重置
}
const seaCher = (val) => { //搜索
  console.log(val, '12')
}
</script>
<style lang="scss" scoped>
.searchValue {
  display: flex;
}
</style>