<!--
 * @Author: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @Date: 2024-04-10 10:00:37
 * @LastEditors: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @LastEditTime: 2024-05-14 09:28:01
 * @FilePath: \demo_ele\src\views\login.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div id="poster">
    <el-form class="login-container" label-position="left" label-width="0px">
      <h3 class="login_title">系统登录</h3>
      <el-form-item>
        <el-input type="text" v-model="loginForm.email" auto-complete="off" placeholder="邮箱"></el-input>
      </el-form-item>
      <el-form-item>
        <el-input type="password" v-model="loginForm.password" auto-complete="off" placeholder="密码"></el-input>
      </el-form-item>
      <el-form-item style="width: 100%" @click="register">
        <el-button type="primary" style="width: 100%;background: #505458;border: none">登录</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script setup>
import { reactive, ref } from 'vue';
import { useRouter } from 'vue-router';
import { login } from "@/config/http"
import { ElMessage } from 'element-plus'
const loginForm = reactive({ password: "", email: "", })
const router = useRouter()
const register = () => {
  if (loginForm.password == '' || loginForm.email == '') {
    ElMessage({
      type: 'info',
      message: '邮箱或密码不能为空',
    })
    return
  } else {
    login(loginForm).then((res) => {
      ElMessage({
        type: 'success',
        message: '登陆成功',
      })
      router.push({ //路由跳转
        path: "/userIndex",
        query: {}
      })
      sessionStorage.setItem('user-token', `Bearer ` + res.accessToken);
    }).catch(() => {
      ElMessage({
        type: 'info',
        message: '登陆失败',
      })
    })
  }
}
</script>
<style scoped>
#poster {
  background-image: url("../asstes/bglog.jpg");
  background-position: center;
  background-size: cover;
  height: 100%;
  width: 100%;
  position: fixed;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
body {
  margin: 0px;
  padding: 0;
}
.login-container {
  border-radius: 15px;
  background-clip: padding-box;
  margin: 0 auto;
  width: 350px;
  padding: 35px 35px 15px 35px;
  background: #fff;
  border: 1px solid #eaeaea;
  box-shadow: 0 0 25px #cac6c6;
}
.login_title {
  margin: 0px auto 40px auto;
  text-align: center;
  color: #505458;
}
</style>