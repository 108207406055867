<!--
 * @Author: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @Date: 2024-05-17 14:57:58
 * @LastEditors: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @LastEditTime: 2024-05-17 15:11:56
 * @FilePath: \demo_ele\src\components\formList.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <el-input v-model="value" autocomplete="off" v-if="itemList.type === 'input'" />
  <el-switch v-model="value" :active-value="1" :inactive-value="0" v-if="itemList.type === 'switch'"></el-switch>
</template>
<script setup>
const value = defineModel('value')
const props = defineProps({
  itemList: {
    type: Object,
    default: {}
  }
})
</script>
<style scoped>
</style>