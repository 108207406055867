<!--
 * @Author: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @Date: 2024-04-12 10:02:58
 * @LastEditors: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @LastEditTime: 2024-05-17 12:05:27
 * @FilePath: \demo_ele\src\views\userComponent\components\userDetail.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="searchValue">
    <search @seaCher="seaCher" @rest="rest"></search>
  </div>
  <div>
    <el-table :data="tableDetails">
      <el-table-column :prop="item.prop" :label="item.label" v-for="(item,index) in cloData" :key="index">
      </el-table-column>
      <el-table-column prop="images" label="图片"> 
        <template #default="{row}">
          <img :src="row.images" alt="">
        </template>
      </el-table-column>
      <el-table-column prop="status" label="审核状态">
        <template #default={row}>
          <span>{{fn[row.status]}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="tableDetails" label="客户ID">
        <template #default={row}>
          <span>{{row.client.id}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="tableDetails" label="客户昵称">
        <template #default={row}>
          <span>{{row.client.nick}}</span>
        </template>
      </el-table-column>
    </el-table>
  </div>
  <div>
    <pagination :painData="painData" @handleSizeChange="handleSizeChange" @handleCurrentChange="handleCurrentChange"></pagination>
  </div>
</template>
<script setup >
import { getUserDiary } from "@/config/http"
import { ref, reactive, onMounted } from "vue"
import search from "@/components/search"
import pagination from "@/components/pagination.vue"
const tableDetails = ref()
const fn = ref({
  0: "待审核",
  1: "审核通过",
  2: "审核未通过",
})
const painData = reactive({
  total: 0,//一共多少条
  pageSize: 10, //一页多少条
  currentPage: 1, //第几页
})
const cloData = ref([
  { prop: "title", label: "标题" },
  { prop: "link", label: "商品链接" },
  { prop: "content_id", label: "描述" },
  { prop: "applaud", label: "获赞数量" },
  { prop: "collect", label: "收藏数量" },
  { prop: "audit_refuse_reason", label: "审核不通过备注" },
  { prop: "audit_at", label: "审核时间" },
])
const props = defineProps({
  detailId: {
    type: String,
    default: "",
  },
});
onMounted(() => {
  getList()
})
const getList = () => {
  const params = ({
    'page[number]': painData.currentPage,
    'page[size]': painData.pageSize
  })
  getUserDiary(props.detailId, params).then((res) => {
    tableDetails.value = res.data
    painData.total = res.total
  })
}
const handleSizeChange = (val) => { //上一页
  painData.currentPage = val
  getList()
}
const handleCurrentChange = (val) => { //下一页
  painData.currentPage = val
  getList()
}
const seaCher = (e) => { }
const rest = () => {
  getList()
}
</script>
<style lang="scss">
</style>