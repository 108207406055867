<!--
 * @Author: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @Date: 2024-04-10 16:04:46
 * @LastEditors: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @LastEditTime: 2024-04-23 17:06:07
 * @FilePath: \demo_ele\src\views\homePage\userMange\userInfo.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div>
    <el-tabs type="border-card" class="demo-tabs" @tab-click="tab">
      <el-tab-pane label="用户信息">
        <userOne>用户信息</userOne>
      </el-tab-pane>
      <!-- <el-tab-pane label="审核管理"> -->
      <!-- <userTwo>审核管理</userTwo> -->
      <!-- </el-tab-pane> -->
    </el-tabs>
  </div>
</template>
<script setup>
import userOne from "@/views/userComponent/userOne.vue"
// import userTwo from "@/views/userComponent/userTwo.vue"
const tab = (e) => {
  console.log(e)
}
</script>
<style scoped></style>