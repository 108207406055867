<!--
 * @Author: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @Date: 2024-04-10 10:46:52
 * @LastEditors: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @LastEditTime: 2024-04-26 10:19:22
 * @FilePath: \demo_ele\src\views\homePage\index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <!-- element-plus -->
  <el-container>
    <!-- 左侧 -->
    <el-aside width="200px">
      <CommonAside></CommonAside>
    </el-aside>
    <el-container>
      <!-- 头部 -->
      <el-header>
        <CommonHeader></CommonHeader>
      </el-header>
      <!-- 右侧 路由组件 -->
      <el-main>
        <router-view></router-view>
      </el-main>
    </el-container>
  </el-container>
</template>
<script setup>
import CommonHeader from "../../components/commonheader.vue"
import CommonAside from "../../components/commonaside.vue"
const debounce = (fn, delay) => {//解决plus中table重置错误
  let timer = null;
  return function () {
    let context = this;
    let args = arguments;
    clearTimeout(timer);
    timer = setTimeout(function () {
      fn.apply(context, args);
    }, delay);
  };
};
const _ResizeObserver = window.ResizeObserver;
window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
  constructor(callback) {
    callback = debounce(callback, 16);
    super(callback);
  }
};
</script>