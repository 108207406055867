<!--
 * @Author: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @Date: 2024-04-12 10:02:58
 * @LastEditors: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @LastEditTime: 2024-04-23 10:45:29
 * @FilePath: \demo_ele\src\views\userComponent\components\userDetail.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="searchValue">
    <search @seaCher="seaCher" @rest="rest"></search>
  </div>
  <div>
    <el-table :data="tableDetails">
      <!-- <el-table-column prop="id" label="ID" /> -->
      <el-table-column prop="created_at" label="关注时间" />
      <el-table-column prop="tableDetails" label="关注人ID">
        <template #default={row}>
          <span>{{row.follow.id}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="tableDetails" label="关注人昵称">
        <template #default={row}>
          <span>{{row.follow.nick}}</span>
        </template>
      </el-table-column>
    </el-table>
  </div>
  <div>
    <pagination :painData="painData" @handleSizeChange="handleSizeChange" @handleCurrentChange="handleCurrentChange"></pagination>
  </div>
</template>
<script setup >
import { getUserWatchList } from "@/config/http"
import { ref, reactive, onMounted } from "vue"
import search from "@/components/search"
import pagination from "@/components/pagination.vue"
const tableDetails = ref()
const painData = reactive({
  total: 0,//一共多少条
  pageSize: 10, //一页多少条
  currentPage: 1, //第几页
})
onMounted(() => {
  getList()
})
const props = defineProps({
  detailId: {
    type: String,
    default: "",
  },
});
const getList = () => {
  const params = ({
    'page[number]': painData.currentPage,
    'page[size]': painData.pageSize
  })
  getUserWatchList(props.detailId, params).then((res) => {
    tableDetails.value = res.data
    painData.total = res.total
  })
}
const handleSizeChange = (val) => { //上一页
  painData.currentPage = val
  getList()
}
const handleCurrentChange = (val) => { //下一页
  painData.currentPage = val
  getList()
}
const seaCher = (e) => { }
const rest = () => {
  getList()
}
</script>
<style lang="scss">
</style>