<!--
 * @Author: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @Date: 2024-04-19 15:16:27
 * @LastEditors: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @LastEditTime: 2024-04-25 16:38:34
 * @FilePath: \demo_ele\src\views\authorPage\authorComponents\dataTable.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div style="margin-top:20px;">
    <el-table :data="tableData" border style="width: 100%">
      <el-table-column :prop="item.prop" :label="item.label" v-for="(item,index) in props.cloData" :key="index">
      </el-table-column>
      <!-- <el-table-column prop="props.tableData" label="是否为精选作者">
        <template #default="{row}">
          <el-switch v-model="row.is_featured" :active-value="1" :inactive-value="0" />
        </template>
      </el-table-column>
      <el-table-column prop="props.tableData" label="是否受欢迎">
        <template #default="{row}">
          <el-switch v-model="row.is_popularity" :active-value="1" :inactive-value="0" />
        </template>
      </el-table-column>
      <el-table-column prop="props.tableData" label="操作">
        <template #default="{row}">
          <el-button type="primary" @click="operate(row)" disabled>操作</el-button>
        </template>
      </el-table-column> -->
    </el-table>
  </div>
</template>
<script setup>
import { ref } from "vue"
const props = defineProps({
  tableData: {
    type: Object,
    default: []
  },
  cloData: {
    type: Array,
    default: []
  },
})
const emit = defineEmits(['operate'])
const operate = (e) => {
  emit('operate', e)
}
</script>
<style lang="scss">
</style>