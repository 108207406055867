<template>
  <div class="header">
    <!-- 菜单图标 -->
    <div class="menu">
    </div>
    <!-- content -->
    <div class="header-content">
      <div class="block">
        <el-avatar shape="square" :size="size" :src="squareUrl" />
      </div>
      <div class="onLogin">
        <el-dropdown>
          <el-icon class="el-icon--right" size="20" color="black"><arrow-down /></el-icon>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item @click="onLogin">退出登录</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
    </div>
    <div class="breadcrumb">
      <!-- 面包屑 -->
      <el-breadcrumb separator=">">
        <el-breadcrumb-item to="/userIndex">
          <el-button type="primary">
            首页
          </el-button>
        </el-breadcrumb-item>
        <template v-for="(item, index) in breadList">
          <el-breadcrumb-item v-if="item.name" :key="index" :to="item.path">
            <el-button type="primary">
              {{ item.name }}
            </el-button>
          </el-breadcrumb-item>
        </template>
      </el-breadcrumb>
    </div>
  </div>
</template>
<script setup>
import { ref, components, onMounted, watch } from "vue"
import { useRoute, useRouter } from 'vue-router'
import { unLogin } from "@/config/http.js"
import { ElMessage, ElMessageBox } from 'element-plus'
const route = useRoute();
const router = useRouter();
// const breadcrumbItems = ref({});
const breadList = ref()
const squareUrl = ref('https://cube.elemecdn.com/9/c2/f0ee8a3c7c9638a54940382568c9dpng.png')
const onLogin = () => {
  ElMessageBox.confirm(
    '此操作将退出登录, 是否继续?', '提示',
    {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning',
    }
  ).then(() => {
    unLogin().then((res) => {
      ElMessage({
        type: 'success',
        message: "退出登录成功",
      })
      router.push({
        path: "/"
      })
      sessionStorage.clear();
    }).catch(() => {
      ElMessage({
        type: 'info',
        message: "退出登录失败",
      })
    })
  }).catch(() => {

  })

}
const matchList = () => {
  breadList.value = route.matched
}
onMounted(() => {
  // updateBreadcrumbs();
  matchList();
});

watch(route, () => {
  // updateBreadcrumbs();
  // matchList()
  breadList.value = route.matched

});

// const updateBreadcrumbs = () => {
//   let currentRoute = route;
//   const items = ref([])
//   const label = currentRoute.meta.title || currentRoute.name;
//   if (label == '首页') {
//     breadcrumbItems.value = {}
//   } else {
//     breadcrumbItems.value.name = currentRoute.name
//     breadcrumbItems.value.path = currentRoute.path
//   }
// };
</script>
<style lang="scss">
.breadcrumb {
  width: 80%;
  height: 40px;
  // backgr.ound-color: red;
  position: absolute;
  left: 40px;
  bottom: 0;
  display: flex;
  align-items: center;
}
.el-header {
  padding: 0 !important;
}

.header {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #545c64;
  position: relative;
}
.header-content {
  position: relative;
}
.onLogin {
  position: absolute;
  top: 30px;
  right: 30px;
}
.block {
  width: 50px;
  height: 50px;
  margin: 10px 50px 0 0;
}
</style>