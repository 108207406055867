<!--
 * @Author: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @Date: 2024-04-10 11:17:50
 * @LastEditors: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @LastEditTime: 2024-04-29 13:56:45
 * @FilePath: \demo_ele\src\components\commonaside.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="aside">
    <!-- 菜单图标 -->
    <div class="menu">
      <!-- <img src="@/asstes/result.jpeg" alt=""> -->
      寻找品味生活
    </div>
    <div class="aside-content">
      <el-menu :default-active="defaultActive" class="el-menu-vertical-demo" :collapse="isCollapse" :router="true" :unique-opened="true" background-color="#545c64" text-color="#fff" active-text-color="#ffd04b">
        <el-menu-item index="/userIndex">
          <template #title>
            <el-icon>
              <Menu />
            </el-icon>
            <span>首页</span>
          </template>
        </el-menu-item>
        <el-sub-menu index="1">
          <template #title>
            <el-icon>
              <location />
            </el-icon>
            <span>用户管理</span>
          </template>
          <el-menu-item-group>
            <el-menu-item index="/userInfo">用户列表</el-menu-item>
          </el-menu-item-group>
          <el-menu-item-group>
            <el-menu-item index="/labelUser">标签列表</el-menu-item>
          </el-menu-item-group>
        </el-sub-menu>
        <el-sub-menu index="2">
          <template #title>
            <el-icon>
              <location />
            </el-icon>
            <span>审核管理</span>
          </template>
          <el-menu-item-group>
            <el-menu-item index="/auditUser">作者审核列表</el-menu-item>
          </el-menu-item-group>
          <el-menu-item-group>
            <el-menu-item index="/imageUser">形象照审核列表</el-menu-item>
          </el-menu-item-group>
          <el-menu-item-group>
            <el-menu-item index="/diaryUser">日记审核列表</el-menu-item>
          </el-menu-item-group>
        </el-sub-menu>
        <el-sub-menu index="4">
          <template #title>
            <el-icon>
              <location />
            </el-icon>
            <span>聊天信息管理</span>
          </template>
          <el-menu-item-group>
            <el-menu-item index="/chitchatUser">聊天记录</el-menu-item>
          </el-menu-item-group>
        </el-sub-menu>
        <el-sub-menu index="5">
          <template #title>
            <el-icon>
              <location />
            </el-icon>
            <span>列表管理</span>
          </template>
          <el-menu-item-group>
            <el-menu-item index="/listData">日记列表</el-menu-item>
          </el-menu-item-group>
          <el-menu-item-group>
            <el-menu-item index="/reportUser">举报列表</el-menu-item>
          </el-menu-item-group>
          <el-menu-item-group>
            <el-menu-item index="/problemFeedback">问题反馈列表</el-menu-item>
          </el-menu-item-group>
          <el-menu-item-group>
            <el-menu-item index="/authorUser">作者列表</el-menu-item>
          </el-menu-item-group>
          <el-menu-item-group>
            <el-menu-item index="/imagePhoto">形象照列表</el-menu-item>
          </el-menu-item-group>
          <el-menu-item-group>
            <el-menu-item index="/userBlockList">拉黑列表</el-menu-item>
          </el-menu-item-group>
        </el-sub-menu>
        <el-sub-menu index="6">
          <template #title>
            <el-icon>
              <location />
            </el-icon>
            <span>APP平台管理</span>
          </template>
          <el-menu-item-group>
            <el-menu-item index="/platformUser">版本管理</el-menu-item>
          </el-menu-item-group>
        </el-sub-menu>
        <el-sub-menu index="7">
          <template #title>
            <el-icon>
              <location />
            </el-icon>
            <span>文章管理</span>
          </template>
          <el-menu-item-group>
            <el-menu-item index="/userAricle">文章列表管理</el-menu-item>
          </el-menu-item-group>
        </el-sub-menu>
        <el-sub-menu index="8">
          <template #title>
            <el-icon>
              <location />
            </el-icon>
            <span>字典管理</span>
          </template>
          <el-menu-item-group>
            <el-menu-item index="/userDictionaries">字典列表管理</el-menu-item>
          </el-menu-item-group>
        </el-sub-menu>
      </el-menu>
    </div>
  </div>
  <!-- <div class="nav">
    <el-menu :default-active="curPath" :unique-opened="true" class="el-menu-vertical">
      <template v-for="(item, mainIndex) in menuList" :key="mainIndex + ''">
        <router-link :to="item.path" v-if="!item.children">
          <el-menu-item :index="item.path">
            <el-icon :size="20">
              <component :is="item.icon" />
            </el-icon>
            <span>{{ item.name }}</span>
          </el-menu-item>
        </router-link>
        <el-sub-menu :index="item.path" v-else>
          <template #title>
            <el-icon :size="20">
              <component :is="item.icon" />
            </el-icon>
            <span>{{ item.name }}</span>
          </template>
          <router-link :to="item.path + '/' + subItem.path" v-for="(subItem, subIndex) in item.children" :key="subIndex + ''">
            <el-menu-item :index="item.path + '/' + subItem.path">
              <el-icon :size="20">
                <component :is="subItem.icon" />
              </el-icon>
              <span>{{ subItem.name }}</span>
            </el-menu-item>
          </router-link>
        </el-sub-menu>
      </template>
    </el-menu>
  </div> -->
</template>
<script setup>
import { ref, watch, onMounted } from "vue"
import { useRoute, onBeforeRouteUpdate } from 'vue-router'
import { routes } from "@/router";
const isCollapse = ref(false)
const router = useRoute()
const defaultActive = ref('')
const curPath = ref()
const menuList = ref()
watch(router, (value) => {
  defaultActive.value = value.path
}, { immediate: true }
);
onMounted(() => {
  init()
  getRouter()
})
const getRouter = () => {
  onBeforeRouteUpdate(to => {
    curPath.path = to.path
  });
}
const init = () => {
  curPath.value = router.fullPath
  menuList.value = router.matched[0].children
}
</script>
<style lang="scss">
.aside {
  width: 100%;
  height: 100%;
}

.menu {
  width: 100%;
  line-height: 60px;
  color: #fff;
  text-align: center;
  font-size: 24px;
  font-family: "Arial Black", sans-serif;
  text-shadow: 0 0 5px #ff0000, /* 红色 */ 0 0 10px #ff0000,
    /* 红色 */ 0 0 15px #ff0000, /* 红色 */ 0 0 20px #ff7f00,
    /* 橙色 */ 0 0 25px #ff7f00, /* 橙色 */ 0 0 30px #ff7f00,
    /* 橙色 */ 0 0 35px #ffff00, /* 黄色 */ 0 0 40px #ffff00; /* 黄色 */
}
</style>