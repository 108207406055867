<template>
  <div style="margin-top:20px;">
    <el-table :data="props.tableData" border style="width: 100%">
      <el-table-column prop="id" label="ID"> </el-table-column>
      <el-table-column prop="key" label="KEY"> </el-table-column>
      <el-table-column prop="group" label="小组"> </el-table-column>
      <el-table-column prop="name" label="NAME"> </el-table-column>
      <el-table-column prop="value" label="Value"> </el-table-column>
      <el-table-column prop="created_at" label="创建时间"> </el-table-column>
      <el-table-column prop="address" label="操作">
        <template #default="{row}">
          <el-button type="primary" @click="upData(row)">修改</el-button>
          <el-button type="primary" @click="detailData(row)">详情</el-button>
          <el-button type="danger" @click="deleteData(row.id)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script setup>
const props = defineProps({
  tableData: {
    type: Object,
    default: []
  },
})
const emit = defineEmits(['upData', 'detailData', 'deleteData'])
const upData = (e) => {
  emit('upData', e)
}
const detailData = (e) => {
  emit('detailData', e)
}
const deleteData = (e) => {
  emit('deleteData', e)
}
</script>
<style lang="scss">
</style>