/*
 * @Author: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @Date: 2024-04-10 15:05:20
 * @LastEditors: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @LastEditTime: 2024-04-27 16:07:49
 * @FilePath: \demo_ele\src\router\home.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
/*
 * @Author: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @Date: 2024-04-10 15:05:20
 * @LastEditors: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @LastEditTime: 2024-04-22 13:48:59
 * @FilePath: \demo_ele\src\router\home.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import platformUser from "@/views/platformVersionPage/platformUser.vue"
import commodityUser from "@/views/commodityPage/commodityUser.vue"
import auditUser from "@/views/auditPage/auditUser.vue"
import diaryUser from "@/views/auditPage/diaryUser.vue"
import imageUser from "@/views/auditPage/imageUser.vue"
import userAricle from "@/views/articlePage/userAricle.vue"
import userDictionaries from "@/views/dictionariesPage/userDictionaries.vue"
const homeRoutes = [
  {
    path: '/auditUser',
    name: '审核列表',
    meta: { title: "审核信息" },
    children: [
      {
        path: '/auditUser',
        name: '审核列表',
        component: auditUser,
        meta: { title: "审核信息" }
      },
      {
        path: '/diaryUser',
        name: '日记审核',
        component: diaryUser,
        meta: { title: "日记审核信息" }
      },
      {
        path: '/imageUser',
        name: '形象照审核',
        component: imageUser,
        meta: { title: "形象照审核信息" }
      },
    ]
  },
  {
    path: '/platformUser',
    name: 'APP平台管理',
    component: platformUser,
  },
  {
    path: '/userAricle',
    name: '文章列表',
    component: userAricle,
  },
  {
    path: '/userDictionaries',
    name: '字典列表',
    component: userDictionaries,
  },
]
export default homeRoutes