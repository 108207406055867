<!--
 * @Author: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @Date: 2024-04-11 10:37:08
 * @LastEditors: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @LastEditTime: 2024-04-29 14:33:30
 * @FilePath: \demo_ele\src\views\chitchatPage\chitchatUser.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div>
    <div class="searchValue">
      <search @seaCher="seaCher" @rest="rest"></search>
    </div>
    <div>
      <dataTable :tableData="tableData" :cloData="cloData"></dataTable>
    </div>
    <div>
      <pagination :painData="painData" @handleSizeChange="handleSizeChange" @handleCurrentChange="handleCurrentChange"></pagination>
    </div>
  </div>
</template>
<script setup>
import { ref, onMounted, reactive } from "vue"
import { getChattingRecords } from "@/config/http.js"
import search from "@/components/search.vue"
import pagination from "@/components/pagination.vue"
import dataTable from "./chitComponents/dataTable.vue"
const tableData = ref()
const sendMsg = ref()
const painData = reactive({
  total: 0,//一共多少条
  pageSize: 10, //一页多少条
  currentPage: 1, //第几页
})
const DialogVisible = ref(false)
const cloData = reactive([
  {
    prop: "id",
    label: "ID"
  },
  {
    prop: "send_id",
    label: "发送消息的ID"
  },
  {
    prop: "receive_id",
    label: "接收消息的ID"
  },
  {
    prop: "msg_time",
    label: "消息时间"
  },
])
const getList = () => {
  const params = ({
    'page[number]': painData.currentPage,
    'page[size]': painData.pageSize
  })
  getChattingRecords(params).then((res) => {
    console.log(res, '111111')
    tableData.value = res.data
    painData.total = res.total
  })
}
const handleSizeChange = (val) => { //上一页
  painData.currentPage = val
  getList()
}
const handleCurrentChange = (val) => { //下一页
  painData.currentPage = val
  getList()
}
const operate = (e) => {
  // console.log(e, '11111')
  DialogVisible.value = true
}
const close = () => {
  DialogVisible.value = false
}
onMounted(() => {
  getList()
})
const seaCher = (e) => { //查询
}
const rest = () => { //重置
  getList()
}
</script>
<style lang="scss">
.searchValue {
  display: flex;
}
</style>