<!--
 * @Author: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @Date: 2024-04-23 10:06:50
 * @LastEditors: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @LastEditTime: 2024-05-20 17:38:49
 * @FilePath: \demo_ele\src\views\userComponent\diaComponents\oneLog.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div v-if="showStatus == 1">
    <el-table :data="props.tableData" border style="width: 100%">
      <el-table-column :prop="item.prop" :label="item.label" v-for="(item,index) in props.cloData" :key="index">
        <template v-slot:default="scope" v-if="item.status">
          <slot :row="scope.row" :name="item.prop"></slot>
        </template>
      </el-table-column>
      <el-table-column prop="hear_url" label="头像">
        <template #default="{row}">
          <img :src="row.hear_url" alt="" style="width:100px;height:100px;">
        </template>
      </el-table-column>
      <el-table-column prop="is_featured" label="是否精选作者">
        <template #default="{row}">
          <el-switch v-model="row.is_featured" :active-value="1" :inactive-value="0" disabled></el-switch>
        </template>
      </el-table-column>
      <el-table-column prop="status" label="账户状态">
        <template #default="{row}">
          <span>{{ fn[row.status] }}</span>
        </template>
      </el-table-column>
      <!-- <el-table-column prop="props.tableData" label="操作" width="160">
        <template #default="{ row }">
          <el-button @click="handleEdit(row.id)">详情</el-button>
          <el-button @click="operateEdit(row)" type="primary">修改</el-button>
        </template>
      </el-table-column> -->
    </el-table>
  </div>
  <div v-if="showStatus == 2">
    <el-table :data="props.tableData" border style="width: 100%">
      <el-table-column :prop="item.prop" :label="item.label" v-for="(item,index) in cloData" :key="index">
      </el-table-column>
      <el-table-column prop="props.tableData" label="审核状态">
        <template #default="{row}">
          <span>{{row.status == 0 ? "资料填写" : row.status == 1 ? "人脸识别" :  row.status == 2 ? "等待审核" : row.status == 3 ? "审核失败" : "审核成功"}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="props.tableData" label="用户ID">
        <template #default={row}>
          <span>{{row.client.id}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="props.tableData" label="用户昵称">
        <template #default={row}>
          <span>{{row.client.nick}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="props.tableData" label="作者审核">
        <template #default="{row}">
          <el-button type="primary" @click="handle(row)">操作</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script setup>
import { ref } from "vue"
const props = defineProps({
  tableData: {
    type: Array,
    default: []
  },
  showStatus: {
    type: Number,
    default: 1
  },
  cloData: {
    type: Array,
    default: []
  }
})
const fn = ref({
  1: "正常"
})

const emit = defineEmits(['handleEdit', 'operateEdit', 'handle'])
const handleEdit = (e) => {
  emit('handleEdit', e)
}
const operateEdit = (e) => {
  emit('operateEdit', e)
}
const handle = (e) => {
  emit('handle', e)
}
</script>
<style lang="scss">
</style>
