<template>
  <el-dialog v-model="props.dialogVisible" :title="props.title" width="40%" draggable @close="close(props.dialogVisible)">
    <div v-if="showStatus == 1">
      <el-form :model="createData">
        <el-form-item :label="item.label" :label-width="formLabelWidth" v-for="(item,index) in createData" :key="index">
          <el-input v-model="form[item.name]" autocomplete="off" :placeholder="item.placeholder" />
        </el-form-item>
      </el-form>
    </div>
    <div v-if="showStatus == 2">
      <el-form :model="createData">
        <el-form-item :label="item.label" :label-width="formLabelWidth" v-for="(item,index) in createData" :key="index">
          <el-input v-model="upDatalist[item.name]" autocomplete="off" :placeholder="item.placeholder" />
        </el-form-item>
      </el-form>
    </div>
    <div v-if="showStatus == 3">
      <el-table :data="props.detailList" stripe style="width: 100%">
        <el-table-column :prop="item.prop" :label="item.label" v-for="(item,index) in detailColumn" :key="index" />
      </el-table>
    </div>
    <template #footer v-if="showStatus == 1 || showStatus == 2">
      <div class="dialog-footer">
        <el-button @click="close(props.dialogVisible)">取消</el-button>
        <el-button type="primary" @click="submit" v-if="showStatus == 1">
          确认创建
        </el-button>
        <el-button type="primary" @click="upSubmit" v-if="showStatus == 2">
          确认修改
        </el-button>
      </div>
    </template>
  </el-dialog>
</template>
<script setup>
import { ref, reactive } from "vue"
const formLabelWidth = ref(125)
const form = reactive({
  group: "",
  name: "",
  key: "",
  value: "",
})
const detailColumn = ref([
  { prop: "group", label: "分组NAME", },
  { prop: "name", label: "说明", },
  { prop: "key", label: "KEY", },
  { prop: "value", label: "Value", },
])
const createData = reactive([ //映射的JSON
  { name: "group", label: "分组NAME", placeholder: "请输入分组值" },
  { name: "name", label: "说明", placeholder: "请输入说明" },
  { name: "key", label: "KEY", placeholder: "请输入KEY值" },
  { name: "value", label: "Value", placeholder: "请输入Value值" },
])
const props = defineProps({
  dialogVisible: {
    type: Boolean,
    default: false
  },
  title: {
    type: String,
    default: ""
  },
  showStatus: {
    type: Number,
    default: 0
  },
  upDatalist: {
    type: Object,
    default: "",
  },
  detailList: {
    type: Array,
    default: "",
  },
})
const emit = defineEmits(['close', 'submit'])
const close = (e) => {
  emit('close', e)
}
const submit = (e) => {
  emit('submit', form)
}
const upSubmit = (e) => {
  emit('upSubmit', props.upDatalist)
}
</script>