<!--
 * @Author: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @Date: 2024-04-19 15:16:27
 * @LastEditors: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @LastEditTime: 2024-05-20 15:02:05
 * @FilePath: \demo_ele\src\views\authorPage\authorComponents\dataTable.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div style="margin-top:20px;">
    <el-table :data="props.tableData" border style="width: 100%">
      <el-table-column :prop="item.prop" :label="item.label" v-for="(item,index) in props.cloData" :key="index">
      </el-table-column>
      <el-table-column prop="props.tableData" label="消息内容">
        <template #default="{row}">
          <div v-for="(item,index) in JSON.parse(row.msg_body)" :key="index">
            <span>消息类型 : </span><span>{{ fn[item.MsgType] }}</span><br>
            <span>消息内容 : </span><span v-if="item.MsgType == 'TIMTextElem'">{{ item.MsgContent.Text }}</span>
            <span v-else>
              <!-- <div v-for="(ite,ind) in item.MsgContent.ImageInfoArray" :key="ind">
                <img :src="ite.URL" alt="" style="width:40px;height:40px;" lazy="true">
              </div> -->

              <span v-if="!item.MsgContent.ImageInfoArray || !item.MsgContent.ImageInfoArray.length"></span>
              <img v-else :src="item.MsgContent.ImageInfoArray[0].URL" alt="" style="width:40px;height:40px;" lazy="true" />
              <!-- {{ item.MsgContent.ImageInfoArray  instanceof Array }} -->
            </span>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="tableData" label="发送人的信息">
        <template #default="{row}">
          <span>ID : {{ row.send_user_info.id }}</span>
          <span> 昵称 : {{ row.send_user_info.nick }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="tableData" label="接收人的信息">
        <template #default="{row}">
          <span>ID : {{ row.receive_user_info.id }}</span>
          <span> 昵称 : {{ row.receive_user_info.nick }}</span>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script setup>
import { ref } from "vue"
const fn = ref({
  TIMTextElem: "文本消息",
  TIMImageElem: "图像消息"
})
const props = defineProps({
  tableData: {
    type: Object,
    default: []
  },
  cloData: {
    type: Array,
    default: []
  },
})
</script>
<style lang="scss">
</style>