<!--
 * @Author: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @Date: 2024-04-15 09:29:00
 * @LastEditors: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @LastEditTime: 2024-05-06 11:44:47
 * @FilePath: \demo_ele\src\components\overallTable.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div style="margin-top:20px;">
    <el-table :data="props.tableDetails" border style="width: 100%">
      <el-table-column :prop="item.prop" :label="item.label" v-for="(item,index) in props.cloData" :key="index">
      </el-table-column>
      <el-table-column prop="props.tableDetails" label="头像">
        <template #default="{row}">
          <img :src="row.hear_url" alt="" style="width:100px;height:100px;">
        </template>
      </el-table-column>
      <el-table-column prop="is_featured" label="是否精选作者">
        <template #default="{row}">
          <el-switch v-model="row.is_featured" :active-value="1" :inactive-value="2" disabled></el-switch>
        </template>
      </el-table-column>
      <el-table-column prop="status" label="账户状态">
        <template #default="{row}">
          <span>{{ row.status == 1 ? "正常" : "失败"}}</span>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script setup>
const props = defineProps({
  tableDetails: {
    type: Array,
    default: []
  },
  cloData: {
    type: Array,
    default: []
  },
  showStatus: {
    type: Number,
    default: ""
  },
  switchData: {
    type: Array,
    default: ""
  }
})
</script>
<style lang="scss" scoped>
</style>