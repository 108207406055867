<!--
 * @Author: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @Date: 2024-04-10 16:35:41
 * @LastEditors: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @LastEditTime: 2024-04-10 17:56:36
 * @FilePath: \demo_ele\src\components\childHome\pageOne.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div>
    <el-row :gutter="20">
      <el-col :span="6" v-for="(item, index) in boxArr" :key="index">
        <div class="tab">
          <div class="cont">
            <div class="cont_one">
              <div style="margin-top:15px;">
                <el-avatar :size="160" :src="require('@/asstes/bglog.jpg')" />
              </div>
            </div>
            <div class="cont_two">
              <div>
                姓名 : {{ item.name }}
              </div>
              <div>
                星星币 : {{ item.xling }}
              </div>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script setup>
import { ref, reactive } from "vue";
const boxArr = reactive([
  { name: "玛丽露娜", src: "@/asstes/bglog.jpg", xling: "123" },
  { name: "埃尔文", src: "@/assets/aver", xling: "123" },
  { name: "雷达斯", src: "@/assets/aver", xling: "123" },
  { name: "星孛儿", src: "@/assets/aver", xling: "123" },
])
</script>
<style scoped lang="scss">
.tab {
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: space-between;
  background-color: #a1c4fd;
  box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.75);
  /* 阴影效果 */
}

.cont {
  width: 100%;
  height: 100%;
  display: flex;
  // justify-content: space-between;
  align-items: center;

  .cont_one {
    width: 50%;
    height: 100%;
  }
  .cont_two {
    width: 50%;
    height: 100%;
    font-size: 15px;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
}
</style>