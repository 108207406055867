/*
 * @Author: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @Date: 2024-04-10 09:36:57
 * @LastEditors: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @LastEditTime: 2024-04-26 14:33:20
 * @FilePath: \demo_ele\src\router\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { createRouter, createWebHistory } from 'vue-router'
import login from "../views/login.vue"
import homeRoutes from './home.js'
import pageRouts from './page.js'
import chatRouts from './chat.js'
import listRouts from './list.js'
import index from "@/views/homePage/index.vue"
import userIndex from "@/views/homePage/userMange/userIndex.vue"
const routes = [
  {
    path: '/',
    name: '登录',
    component: login,
  },
  {
    path: '/userIndex',
    component: index,
    meta: { title: "首页" },
    children: [
      {
        path: '',
        name: '首页',
        component: userIndex,
        meta: { title: "首页" },
      },
      ...homeRoutes, ...pageRouts, ...chatRouts, ...listRouts
    ],
  },
]
const router = createRouter({
  history: createWebHistory(),
  routes,
})

export default router
