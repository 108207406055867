/*
 * @Author: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @Date: 2024-04-10 15:32:21
 * @LastEditors: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @LastEditTime: 2024-04-30 19:02:47
 * @FilePath: \demo_ele\src\utils\request.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import axios from 'axios'
// const BASE_URL = 'http://192.168.2.107:8000'
const BASE_URL = "https://api.sqya.cc" //打包的时候用的地址
// const url = BASE_URL + '/admin-api'
const url = BASE_URL + '/admin'
// 创建一个 axios 实例
const service = axios.create({
  baseURL: url, // 所有的请求地址前缀部分
  timeout: 5000, // 请求超时时间毫秒
})

// 添加请求拦截器
service.interceptors.request.use(
  config => {
    // 是否需要设置 token
    const isToken = (config.headers || {}).isToken === false
    if (!isToken) {
      config.headers['Authorization'] = sessionStorage.getItem('user-token') || ''// 添加token
    }
    return config
  }
)

// 添加响应拦截器
service.interceptors.response.use(
  function (response) {
    // console.log(response)
    // dataAxios 是 axios 返回数据中的 data
    const dataAxios = response.data
    // 这个状态码是和后端约定的
    // const code = dataAxios.reset
    return dataAxios
  },
  function (error) {
    // 超出 2xx 范围的状态码都会触发该函数。
    // 对响应错误做点什么
    // console.log(error)
    return Promise.reject(error)
  }
)

export default service
