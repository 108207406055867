<template>
  <div id="wrapper">
    <div class="searchValue">
      <search @seaCher="seaCher" @rest="rest"></search>
    </div>
    <div>
      <el-button type="primary" @click="setData" style="float:right">
        <el-icon>
          <Plus />
        </el-icon>
        <span>
          创建标签
        </span>
      </el-button>
    </div>
    <div>
      <tableDataDetails :tableData="tableData" :cloData="cloData" @upData="upData" @detailData="detailData" @deleteData="deleteData"></tableDataDetails>
    </div>
    <div>
      <pagination :painData="painData" @handleSizeChange="handleSizeChange" @handleCurrentChange="handleCurrentChange"></pagination>
    </div>
    <div>
      <diaLog :dialogVisible="dialogVisible" :title="title" @close="close" :showStatus="showStatus" :detailList="detailList" :detailCloum="detailCloum" @setData="setDataClick" :textTitle="textTitle" :upDatalist="upDatalist" @upListData="upListData" :createData="createData" :form="form">
      </diaLog>
    </div>
  </div>
</template>
<script setup>
import { ref, onMounted, reactive } from "vue"
import { getVersionList, getVersionDetail, creVersion, upVersion, deleteVersion } from "@/config/http.js"
import { ElMessage, ElMessageBox } from 'element-plus'
import search from "@/components/search.vue"
import pagination from "@/components/pagination.vue"
import diaLog from "./plateComponents/dialog.vue"
import tableDataDetails from "./plateComponents/tableData.vue"
const tableData = ref()
const title = ref()
const showStatus = ref()
const dialogVisible = ref(false)
const detailList = ref()
const textTitle = ref()
const upDatalist = reactive({
  id: "",
  device_type: "",
  platform: "",
  version: "",
  force_version: "",
  app_url: "",
  remark: "",
  users: "",
})
const form = reactive({
  device_type: "",
  platform: "",
  version: "",
  force_version: "",
  app_url: "",
  remark: "",
  users: "",
})
const cloData = ref([
  { prop: "platform", label: "设备机型" },
  { prop: "version", label: "版本" },
  { prop: "force_version", label: "强制更新版本" },
  { prop: "app_url", label: "强制更新下载地址" },
  { prop: "remark", label: "备注" },
  { prop: "users", label: "审核版本用户ID" },
])
const painData = reactive({
  total: 0,//一共多少条
  pageSize: 10, //一页多少条
  currentPage: 1, //第几页
})
const detailCloum = ref([
  { prop: "platform", label: "平台" },
  { prop: "version", label: "版本" },
  { prop: "force_version", label: "强制更新版本" },
  { prop: "app_url", label: "强制更新下载地址" },
  { prop: "remark", label: "备注" },
  { prop: "users", label: "审核版本用户ID" },
])

const getList = () => {
  const params = ({
    'page[number]': painData.currentPage,
    'page[size]': painData.pageSize
  })
  getVersionList(params).then((res) => {
    tableData.value = res.data
    painData.total = res.total
  })
}
const handleSizeChange = (val) => { //上一页
  painData.currentPage = val
  getList()
}
const handleCurrentChange = (val) => { //下一页
  painData.currentPage = val
  getList()
}
onMounted(() => {
  getList()
})
const setData = () => { //创建
  title.value = "创建"
  dialogVisible.value = true
  showStatus.value = 1
  textTitle.value = "确认创建"
}
const setDataClick = (e, formEl) => { //调用创建接口
  creVersion(e).then((res) => {
    ElMessage({
      type: 'success',
      message: "创建成功",
    })
    dialogVisible.value = false
    getList()
    e.device_type = "",
      e.platform = "",
      e.version = "",
      e.force_version = "",
      e.app_url = "",
      e.remark = "",
      e.users = ""
  }).catch(() => {
    ElMessage({
      type: 'info',
      message: "创建失败",
    })
    dialogVisible.value = false
    e.device_type = "",
      e.platform = "",
      e.version = "",
      e.force_version = "",
      e.app_url = "",
      e.remark = "",
      e.users = ""
  })
}
const upData = (e) => { //修改
  // console.log(e, '123213')
  upDatalist.id = e.id
  upDatalist.platform = e.platform
  upDatalist.version = e.version
  upDatalist.force_version = e.force_version
  upDatalist.app_url = e.app_url
  upDatalist.remark = e.remark
  upDatalist.users = e.users
  upDatalist.device_type = e.device_type
  // console.log(upDatalist.platform)
  title.value = "修改"
  dialogVisible.value = true
  showStatus.value = 2
  textTitle.value = "确认修改"
}
const upListData = (e) => {
  ElMessageBox.confirm(
    '此操作将修改该数据, 是否继续?', '提示',
    {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning',
    }
  ).then(() => {
    const id = e.id
    const params = {
      device_type: e.device_type,
      platform: e.platform,
      version: e.version,
      force_version: e.force_version,
      app_url: e.app_url,
      remark: e.remark,
      users: e.users,
    }
    upVersion(id, params).then((res) => {
      ElMessage({
        type: 'success',
        message: "修改成功",
      })
      getList()
      dialogVisible.value = false
    }).catch(() => {
      ElMessage({
        type: 'info',
        message: "修改失败",
      })
      dialogVisible.value = false
    })
  }).catch(() => { })

}
const detailData = (e) => { //详情
  title.value = "详情"
  dialogVisible.value = true
  showStatus.value = 3
  getVersionDetail(e.id).then((res) => {
    detailList.value = [res.data]
  })
}
const deleteData = (e) => { //删除
  ElMessageBox.confirm(
    '此操作将删除该数据, 是否继续?', '提示',
    {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning',
    }
  ).then(() => {
    deleteVersion(e).then((res) => {
      ElMessage({
        type: 'success',
        message: "删除成功",
      })
      getList()
    }).catch(() => {
      ElMessage({
        type: 'info',
        message: "删除失败",
      })
    })
  }).catch(() => { })

}
const close = (e) => {
  if (e == true) {
    dialogVisible.value = false
    form.device_type = ""
    form.platform = ""
    form.version = ""
    form.force_version = ""
    form.app_url = ""
    form.remark = ""
    form.users = ""
    getList()
  }
}
const seaCher = () => { //查询

}
const rest = () => { //重置

}
</script>
<style <style lang="scss" scoped>
.searchValue {
  display: flex;
}
</style>>