<!--
 * @Author: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @Date: 2024-04-27 13:43:22
 * @LastEditors: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @LastEditTime: 2024-04-29 14:02:32
 * @FilePath: \demo_ele\src\views\articlePage\articleComponents\diaLog.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <el-dialog v-model="props.dialogVisible" :title="props.title" width="40%" draggable @close="close(props.dialogVisible)">
    <div v-if="showStatus == 1">
      <el-form :model="createData">
        <el-form-item :label="item.label" :label-width="formLabelWidth" v-for="(item,index) in createData" :key="index">
          <el-input v-model="form[item.name]" autocomplete="off" :placeholder="item.placeholder" />
        </el-form-item>
        <el-form-item label="文章内容" :label-width="formLabelWidth">
          <el-input v-model="form.content" type="textarea" placeholder="请输入文章内容" style="width:400px" />
        </el-form-item>
      </el-form>
    </div>
    <div v-if="showStatus == 2">
      <el-form :model="createData">
        <el-form-item :label="item.label" :label-width="formLabelWidth" v-for="(item,index) in createData" :key="index">
          <el-input v-model="upDatalist[item.name]" autocomplete="off" :placeholder="item.placeholder" />
        </el-form-item>
      </el-form>
    </div>
    <div v-if="showStatus == 3">
      <el-table :data="props.detailList" stripe style="width: 100%">
        <el-table-column :prop="item.prop" :label="item.label" v-for="(item,index) in detailColumn" :key="index" />
      </el-table>
    </div>
    <template #footer v-if="showStatus == 1 || showStatus == 2">
      <div class="dialog-footer">
        <el-button @click="close(props.dialogVisible)">取消</el-button>
        <el-button type="primary" @click="submit" v-if="showStatus == 1">
          确认创建
        </el-button>
        <el-button type="primary" @click="upSubmit" v-if="showStatus == 2">
          确认修改
        </el-button>
      </div>
    </template>
  </el-dialog>
</template>
<script setup>
import { ref, reactive } from "vue"
const formLabelWidth = ref(125)
const form = reactive({
  key: "",
  title: "",
  content: "",
})
const detailColumn = ref([
  { prop: "id", label: "ID", },
  { prop: "title", label: "标题", },
  { prop: "content", label: "内容", },
  { prop: "created_at", label: "创建时间", },
])
const createData = reactive([ //映射的JSON
  { name: "key", label: "KEY值", placeholder: "请输入KEY值" },
  { name: "title", label: "标题", placeholder: "请输入标题" },
])
const props = defineProps({
  dialogVisible: {
    type: Boolean,
    default: false
  },
  title: {
    type: String,
    default: ""
  },
  showStatus: {
    type: Number,
    default: 0
  },
  upDatalist: {
    type: Object,
    default: "",
  },
  detailList: {
    type: Array,
    default: "",
  },
})
const emit = defineEmits(['close', 'submit'])
const close = (e) => {
  emit('close', e)
}
const submit = (e) => {
  emit('submit', form)
}
const upSubmit = (e) => {
  emit('upSubmit', props.upDatalist)
}
</script>