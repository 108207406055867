<!--
 * @Author: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @Date: 2024-04-17 17:51:47
 * @LastEditors: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @LastEditTime: 2024-04-22 14:27:07
 * @FilePath: \demo_ele\src\components\search.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <el-input v-model="InputValue" style="width: 240px" :placeholder="props.placeholder" />
  <el-button type="primary" @click="seaCher" style="margin-left:8px;">搜索</el-button>
  <el-button type="primary" @click="rest">重置</el-button>
</template>
<script setup>
import { ref } from "vue"
const props = defineProps({
  placeholder: {
    type: String,
    default: ""
  }
})
const InputValue = ref()
const value = defineModel('value')
const emit = defineEmits(['seaCher', 'rest'])
const seaCher = () => {
  emit('seaCher', InputValue)
}
const rest = () => {
  emit('rest')
}
</script>
<style lang="scss" scoped>
</style>