<!--
 * @Author: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @Date: 2024-04-17 17:51:47
 * @LastEditors: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @LastEditTime: 2024-04-22 16:48:18
 * @FilePath: \demo_ele\src\components\search.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <el-input v-model="title" placeholder="请输入标题" />
  <el-input v-model="nick" placeholder="请输入昵称" v-if="props.showStatus" />
  <el-button type="primary" @click="seaCher" style="margin-left:8px;">搜索</el-button>
  <el-button type="primary" @click="rest">重置</el-button>
</template>
<script setup>
import { ref } from "vue"
// const [title, filter] = defineModel({
//   set (value) {
//     if (filter.upper) {
//       return value.number()
//     }
//     return value
//   }
// })
const props = defineProps({
  showStatus: {
    type: String,
    default: ""
  }
})

const nick = defineModel('nick')
const title = defineModel('title')
const emit = defineEmits(['seaCher', 'rest'])
const seaCher = () => {
  emit('seaCher', title, nick)
}
const rest = () => {
  emit('rest')
}
</script>
<style lang="scss" scoped>
.el-input {
  margin-right: 10px !important;
  width: 200px !important;
}
</style>