<!--
 * @Author: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @Date: 2024-04-23 11:38:34
 * @LastEditors: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @LastEditTime: 2024-05-17 13:34:25
 * @FilePath: \demo_ele\src\views\labelPage\components\tableData.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div>
    <el-table :data="props.tableData" border style="width: 100%">
      <el-table-column :prop="item.prop" :label="item.label" v-for="(item,index) in cloData" :key="index">
      </el-table-column>
      <el-table-column prop="props.tableData" label="状态">
        <template #default="{row}">
          <el-switch v-model="row.status" :active-value="1" :inactive-value="0" disabled></el-switch>
        </template>
      </el-table-column>
      <el-table-column prop="props.tableData" label="操作">
        <template #default="{row}">
          <el-button type="primary" @click="upData(row)">修改</el-button>
          <el-button type="primary" @click="detailData(row.id)">详情</el-button>
          <el-button type="danger" @click="deleteData(row.id)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script setup>
import { ref } from "vue"
const props = defineProps({
  tableData: {
    type: Array,
    default: ""
  },
})
const cloData = ref([
  { prop: "sort", label: "权重" },
  { prop: "id", label: "ID" },
  { prop: "name", label: "昵称" },
  { prop: "updated_at", label: "修改时间" },
])
const emit = defineEmits(['upData', 'detailData', 'deleteData'])
const upData = (e) => {
  emit('upData', e)
}
const detailData = (e) => {
  emit('detailData', e)
}
const deleteData = (e) => {
  emit('deleteData', e)
}
</script>
<style lang="scss">
</style>