<template>
  <el-dialog v-model="props.dialogVisible" :title="props.title" width="40%" draggable @close="close(props.dialogVisible)">
    <div v-if="showStatus == 1">
      <el-form :model="form" ref="ruleFormRef" :rules="rules">
        <el-form-item label="设备类型" :label-width="formLabelWidth" prop="device_type">
          <el-select v-model="props.form.device_type" placeholder="请选择设备类型" style="width: 240px">
            <el-option value="1" label="IOS"></el-option>
            <el-option value="2" label="安卓"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="item.label" :label-width="formLabelWidth" v-for="(item,index) in createData" :key="index" :rules="item.rules" :prop="item.prop">
          <el-input v-model="props.form[item.name]" autocomplete="off" :placeholder="item.placeholder" />
        </el-form-item>
      </el-form>
    </div>
    <div v-if="showStatus == 2">
      <el-form :model="props.upDatalist" :rules="rules" ref="ruleFormRefs">
        <el-form-item label="设备类型" :label-width="formLabelWidth" prop="device_type">
          <el-select v-model="fn[props.upDatalist.device_type]" placeholder="请选择设备类型" style="width: 240px" @change="handleChange">
            <el-option value="1" label="IOS"></el-option>
            <el-option value="2" label="安卓"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="设备机型" :label-width="formLabelWidth" prop="platform">
          <el-input autocomplete="off" v-model="props.upDatalist.platform" placeholder="请选择设备机型" />
        </el-form-item>
        <el-form-item label="版本号" :label-width="formLabelWidth" prop="version">
          <el-input autocomplete="off" v-model="props.upDatalist.version" placeholder="请输入版本号" />
        </el-form-item>
        <el-form-item :label="item.label" :label-width="formLabelWidth" v-for="(item,index) in createDatas" :key="index">
          <el-input autocomplete="off" v-model="upDatalist[item.name]" :placeholder="item.placeholder" />
        </el-form-item>
      </el-form>
    </div>
    <div v-if="showStatus == 3">
      <el-table :data="props.detailList" stripe style="width: 100%">
        <el-table-column :prop="item.prop" :label="item.label" v-for="(item,index) in props.detailCloum" :key="index" />
        <el-table-column prop="device_type" label="设备类型">
          <template #default="{row}">
            <span>{{ fn[row.device_type] }}</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <template #footer v-if="showStatus == 1 || showStatus == 2">
      <div class="dialog-footer">
        <el-button @click="close(props.dialogVisible)">取消</el-button>
        <el-button type="primary" @click="submitForm(ruleFormRef)" v-if="showStatus == 1">
          {{ props.textTitle }}
        </el-button>
        <el-button type="primary" @click="upSubmit(ruleFormRefs)" v-if="showStatus == 2">
          {{ props.textTitle }}
        </el-button>
      </div>
    </template>
  </el-dialog>
</template>
<script setup>
import { ref, reactive } from "vue"
const fn = ref({
  1: "IOS",
  2: "安卓"
})
const formLabelWidth = ref(125)
const ruleFormRef = reactive()
const ruleFormRefs = reactive()
const rules = reactive({
  device_type: [{
    required: true,
    trigger: 'change',
    message: '请选择',
  }],
  platform: [{
    required: true,
    trigger: 'blur',
    message: '请输入设备机型',
  }],
  version: [{
    required: true,
    trigger: 'blur',
    message: '请输入版本号',
  }],
})
const submitForm = async (formEl) => {
  if (!formEl) return
  await formEl.validate((valid, fields) => {
    if (valid) {
      console.log('submit!')
      emit('setData', props.form)
    } else {
      console.log('error submit!', fields)
      // emit('setData', form)
    }
  })
}
const upSubmit = async (formEl) => {
  if (!formEl) return
  await formEl.validate((valid, fields) => {
    if (valid) {
      console.log('submit!')
      emit('upListData', props.upDatalist)
    } else {
      console.log('error submit!', fields)
      // emit('setData', form)
    }
  })
}

const createDatas = reactive([ //映射的JSON
  { name: "force_version", label: "强制更新版本号", placeholder: "请输入强制更新的版本号" },
  { name: "app_url", label: "强制更新下载地址", placeholder: "请输入强制更新下载地址" },
  { name: "remark", label: "备注", placeholder: "请输入备注" },
  { name: "users", label: "审核版本显示ID", placeholder: "请输入ID,以逗号隔开" },
])
const createData = reactive([ //映射的JSON
  {
    name: "platform", label: "设备机型", placeholder: "请输入设备机型", prop: "platform",
    rules: { required: true, message: '请输入设备机型', trigger: 'blur' },
  },
  {
    name: "version", label: "版本号", placeholder: "请输入版本号", prop: "version",
    rules: { required: true, message: '请输入版本号', trigger: 'blur' },
  },
  { name: "force_version", label: "强制更新版本号", placeholder: "请输入强制更新的版本号" },
  { name: "app_url", label: "强制更新下载地址", placeholder: "请输入强制更新下载地址" },
  { name: "remark", label: "备注", placeholder: "请输入备注" },
  { name: "users", label: "审核版本显示ID", placeholder: "请输入ID,以逗号隔开" },
])
const props = defineProps({
  dialogVisible: {
    type: Boolean,
    default: false
  },
  title: {
    type: String,
    default: ""
  },
  showStatus: {
    type: Number,
    default: 0
  },
  detailCloum: {
    type: Array,
    default: ""
  },
  detailList: {
    type: Array,
    default: ""
  },
  textTitle: {
    type: String,
    default: ""
  },
  upDatalist: {
    type: Object,
    default: "",
  },
  form: {
    type: Object,
    default: "",
  }
})
const handleChange = (e) => {
  props.upDatalist.device_type = e
}
const emit = defineEmits(['close', 'upListData', 'upListData'])
const close = (e) => {
  emit('close', e)
}
</script>
<style lang="scss">
.el-input {
  width: 350px !important;
}
</style>