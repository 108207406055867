<!--
 * @Author: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @Date: 2024-04-15 15:05:01
 * @LastEditors: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @LastEditTime: 2024-04-23 15:46:12
 * @FilePath: \demo_ele\src\views\auditPage\imageUser.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div id="wrapper">
    <div class="searchValue">
      <search @seaCher="seaCher" @rest="rest"></search>
    </div>
    <div style="margin-top:20px;">
      <el-table :data="tableData" border style="width: 100%">
        <el-table-column prop="id" label="ID" />
        <el-table-column prop="url" label="图像地址">
          <template #default="{row}">
            <img :src="row.url" alt="" style="width:100px;height:100px">
          </template>
        </el-table-column>
        <el-table-column prop="client_id" label="用户ID" />
        <el-table-column prop="status" label="审核状态">
          <template #default="{row}">
            <span>{{ fn[row.status] }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="created_at" label="创建时间" />
        <el-table-column prop="address" label="操作">
          <template #default="{row}">
            <el-button type="primary" @click="EdithData(row)">操作</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div>
      <pagination :painData="painData" @handleSizeChange="handleSizeChange" @handleCurrentChange="handleCurrentChange"></pagination>
    </div>
    <div>
      <upLog :dialogVisible="dialogVisible" :upData="upData" @getStatus="getStatus" @submit="submit" :showStatus="showStatus"></upLog>
    </div>
  </div>
</template>
<script setup>
import { ref, onMounted, reactive } from "vue"
import { getImagePhotoList, ImagePhotoReview } from "@/config/http.js"
import upLog from "./audiComponents/upLog.vue"
import { ElMessage, ElMessageBox } from 'element-plus'
import search from "@/components/search.vue"
import pagination from "@/components/pagination.vue"
const tableData = ref()
const upData = ref()
const dialogVisible = ref(false)
const showStatus = ref(1)
const fn = ref({
  0: "资料填写",
  1: "人脸识别",
  2: "等待审核",
  3: "审核失败",
  4: "审核成功",
})
const painData = reactive({
  total: 0,//一共多少条
  pageSize: 10, //一页多少条
  currentPage: 1, //第几页
})
const getList = () => {
  const params = ({
    'page[number]': painData.currentPage,
    'page[size]': painData.pageSize
  })
  getImagePhotoList(params).then((res) => {
    tableData.value = res.data
    painData.total = res.total
  }).catch(() => {
  })
}
const handleSizeChange = (val) => { //上一页
  painData.currentPage = val
  getList()
}
const handleCurrentChange = (val) => { //下一页
  painData.currentPage = val
  getList()
}
onMounted(() => {
  getList()
})
const EdithData = (e) => {
  upData.value = e
  // console.log(upData.value)
  showStatus.value = 3
  dialogVisible.value = true
}
const submit = (e, status) => {
  ElMessageBox.confirm(
    '此操作将修改该数据, 是否继续?', '提示',
    {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning',
    }
  ).then(() => {
    const id = e.id
    const params = {
      status: status.value,
    }
    ImagePhotoReview(id, params).then((res) => {
      ElMessage({
        type: 'success',
        message: "修改成功",
      })
      getList()
      dialogVisible.value = false
    }).catch(() => {
      ElMessage({
        type: 'info',
        message: "修改失败",
      })
      dialogVisible.value = false
      getList()
    })
  }).catch(() => { })

}
const getStatus = (e) => { //子组件的关闭父组件的弹框要设置为false否则第二次点击状态还是为true组建的为false
  if (e == true) {
    dialogVisible.value = false
    getList()
  }
}
const seaCher = () => { }
const rest = () => { }
</script>
<style lang="scss" scoped>
.searchValue {
  display: flex;
}
</style>