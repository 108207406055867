<!--
 * @Author: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @Date: 2024-04-22 10:15:30
 * @LastEditors: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @LastEditTime: 2024-04-22 11:47:09
 * @FilePath: \demo_ele\src\views\authorPage\authorComponents\diaLog.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div>
    <el-dialog v-model="DialogVisible" title="修改" width="40%" draggable @close="close(DialogVisible)">
      
    </el-dialog>
  </div>
</template>
<script setup>
import { ref } from "vue"
const DialogVisible = defineModel('DialogVisible')
const emit = defineEmits(['close'])
const close = (e) => {
  emit('close', e)
}
</script>
<style lang="scss" scoped>
</style>