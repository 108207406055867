import reportUser from "@/views/listManagement/reportUser.vue"
import problemFeedback from "@/views/listManagement/problemFeedback.vue"
import authorUser from "@/views/authorPage/authorUser.vue"
import listData from "@/views/listManagement/diayrList.vue"
import imagePhoto from "@/views/listManagement/imagePhoto.vue"
import userBlockList from "@/views/listManagement/userBlockList.vue"
const listRoutes = [
  {
    path: '/listData',
    name: '列表管理',
    meta: { title: "列表管理" },
    children: [
      {
        path: '/listData',
        name: '日记列表',
        component: listData,
        meta: { title: "日记列表" },
      },
      {
        path: '/imagePhoto',
        name: '形象照管理',
        component: imagePhoto,
      },
      {
        path: '/reportUser',
        name: '举报列表',
        component: reportUser,
      },
      {
        path: '/problemFeedback',
        name: '问题反馈列表',
        component: problemFeedback,
      },

      {
        path: '/authorUser',
        name: '审核管理',
        component: authorUser,
      },
      {
        path: '/userBlockList',
        name: '拉黑列表',
        component: userBlockList,
      },
    ]
  },
]
export default listRoutes
