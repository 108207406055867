<template>
  <div id="wrapper">
    <div class="searchValue">
      <search @seaCher="seaCher" @rest="rest"></search>
    </div>
    <div style="margin-top:20px;">
      <oneLog :tableData="tableData" @handleEdit="handleEdit" @operateEdit="operateEdit" :showStatus="showStatus" :cloData="cloData">
        <template v-slot:name="{row}">
          <el-button @click="handleEdit(row.id)">详情</el-button>
          <el-button @click="operateEdit(row)" type="primary">修改</el-button>
        </template>
      </oneLog>
    </div>
    <!-- 分页 -->
    <div>
      <pagination :painData="painData" @handleSizeChange="handleSizeChange" @handleCurrentChange="handleCurrentChange"></pagination>
    </div>
    <!-- 详情 -->
    <diaLog @submit="submit" v-model:uplogVisible="uplogVisible" v-model:dialogVisible="dialogVisible" :detailId="detailId" :upData="upData" :showStatus="showStatus" :key="jgFileTimer"></diaLog>
  </div>
</template>
<script setup>
import { getUserList, upUserList } from "@/config/http"
import { ref, onMounted, reactive } from "vue"
import oneLog from "./diaComponents/oneLog.vue"
import diaLog from "./diaComponents/onediaLog"
import search from "@/components/search.vue"
import pagination from "@/components/pagination.vue"
import { ElMessage, ElMessageBox } from 'element-plus'
const tableData = ref()
const dialogVisible = ref(false)
const uplogVisible = ref(false)
const showStatus = ref(1)
const upData = ref()
const detailId = ref()
const painData = reactive({
  total: 0,//一共多少条
  pageSize: 10, //一页多少条
  currentPage: 1, //第几页
})
const cloData = ref([
  { prop: "id", label: "ID" },
  { prop: "account", label: "账号" },
  { prop: "nick", label: "昵称" },
  { prop: "signature", label: "用户签名" },
  { prop: "fans", label: "粉丝数量" },
  { prop: "applaud", label: "认可获赞数量" },
  { prop: "follow", label: "关注数量" },
  { prop: "log", label: "日记数量" },
  { prop: "popularity", label: "受欢迎基数" },
  { prop: "name", label: "操作", status: true },
])
const jgFileTimer = ref()
onMounted(() => {
  getList()
})
const getList = () => {
  const params = ({
    'page[number]': painData.currentPage,
    'page[size]': painData.pageSize
  })
  getUserList(params).then((res) => { //获取列表
    tableData.value = res.data
    painData.total = res.total
  })
}
const handleSizeChange = (val) => { //上一页
  painData.currentPage = val
  getList()
}
const handleCurrentChange = (val) => { //下一页
  painData.currentPage = val
  getList()
}
const handleEdit = (e) => { //列表详情
  detailId.value = e
  dialogVisible.value = true
  jgFileTimer.value = new Date().getTime();
}
const operateEdit = (e) => {
  upData.value = e
  uplogVisible.value = true
}
const submit = (row) => {
  ElMessageBox.confirm(
    '此操作将修改该数据, 是否继续?', '提示',
    {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning',
    }
  ).then(() => {
    const id = row.id
    const params = {
      is_featured: row.is_featured,
      popularity: row.popularity
    }
    upUserList(id, params).then((res) => {
      ElMessage({
        type: 'success',
        message: "修改成功",
      })
      getList()
      uplogVisible.value = false
    }).catch(() => {
      ElMessage({
        type: 'info',
        message: "修改失败",
      })
      uplogVisible.value = false
      getList()
    })
  }).catch(() => { })
}
const rest = () => { //重置
  getList()
}
const seaCher = (val) => { //搜索
  // console.log(val, '12')
}
</script>
<style scoped lang="scss">
.searchValue {
  display: flex;
}
</style>
