<!--
 * @Author: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @Date: 2024-04-15 16:28:35
 * @LastEditors: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @LastEditTime: 2024-05-17 14:29:32
 * @FilePath: \demo_ele\src\views\auditPage\audiComponents\upLog.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div>
    <el-dialog v-model="props.dialogVisible" title="审核信息" width="40%" draggable @close="close(props.dialogVisible)">
      <div>
        <el-form :inline="true" :model="props.upData" class="demo-form-inline" :rules="rules" ref="fromData">
          <el-form-item label="当前审核状态 : ">
            <el-input v-model="fn[props.upData.status]" disabled />
          </el-form-item>
          <el-form-item label="可修改为 : " v-if="props.showStatus == 1">
            <el-select v-model="status" placeholder="请选择要修改的状态" style="width: 200px">
              <el-option label="审核成功" value="4" />
              <el-option label="审核失败" value="3" />
            </el-select>
          </el-form-item>
          <el-form-item label="可修改为 : " v-else-if="props.showStatus == 2">
            <el-select v-model="status" placeholder="请选择要修改的状态" style="width: 200px">
              <el-option label="审核通过" value="1" />
              <el-option label="审核不通过" value="2" />
            </el-select>
          </el-form-item>
          <el-form-item label="可修改为 : " v-else>
            <el-select v-model="status" placeholder="请选择要修改的状态" style="width: 200px">
              <el-option label="审核通过" value="1" />
              <el-option label="审核不通过" value="2" />
            </el-select>
          </el-form-item>
          <el-form-item label="审核意见 : " v-if="props.showStatus == 1">
            <el-input v-model="props.upData.advice" />
          </el-form-item>
          <el-form-item label="审核不通过意见 : " v-if="props.showStatus == 2 && status == 2" prop="audit_refuse_reason">
            <el-input v-model="props.upData.audit_refuse_reason" />
          </el-form-item>
        </el-form>
      </div>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="props.dialogVisible = false">取消</el-button>
          <el-button type="primary" @click="upSubmit(props.upData)">确定</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>
<script setup>
import { ref, reactive } from "vue"
const props = defineProps({
  dialogVisible: {
    type: Boolean,
    default: false,
  },
  upData: {
    type: Object,
    default: () => { },
  },
  showStatus: {
    type: Number,
    default: 1,
  }
})
const fn = ref({
  0: "资料填写",
  1: "人脸识别",
  2: "等待审核",
  3: "审核失败",
  4: "审核成功",
})
const status = ref()
const rules = reactive({
  audit_refuse_reason: [{
    required: true, message: '请输入拒绝审核意见', trigger: 'blur'
  }]
})
const fromData = ref()
const emit = defineEmits(['getStatus', 'submit'])
const upSubmit = () => {
  fromData.value.validate((valid) => {
    if (valid) {
      // console.log('代表验证通过')
      emit('submit', props.upData, status)
      // console.log(e, '所有数据')
    } else {
      // console.log('代表验证没通过')
    }
  })
}
const close = (e) => {
  emit('getStatus', e)
}
</script>
<style lang="scss" scoped>
</style>